// src/main.js

import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import "./assets/styles.css"
import 'animate.css';

// Import AOS
import AOS from 'aos'
import 'aos/dist/aos.css'


// Initialize AOS
AOS.init()

createApp(App).use(router).mount('#app')
