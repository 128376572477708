<template>
    <div id="ProjetPage">

        <router-link id="btninfos" to="/Infos" class="animate__animated animate__slideInRight">
            <p id="fleche">i</p>
        </router-link>

        <!-- <header>
                <h1 class="animate__animated animate__fadeInUp animate__faster">
                    1. Draw a card from the deck
                </h1>
                <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-1s">
                    2. Find the same circle in the grid below
                </h1>
                <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-2s">
                    3. Tap on it and compare the number
                </h1>
                <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-3s">
                    4. Repeat
                </h1>
            </header> -->

        <img id="logo" class="animate__animated animate__tada" src="@/assets/logo.svg">

        <header>
            <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-1s">
                1. Put the deck of cards next to you and draw a card. The deck is composed of 12 cards.
            </h1>
            <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-2s">
                2. The aim of the game is to find the same circle on the map and the site. Beware of similar colors!
            </h1>
            <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-3s">
                3. When you think you've found the circle, turn the card over and click on the yes or no button if the
                number matches.
            </h1>
            <h1 class="animate__animated animate__fadeInUp animate__faster animate__delay-4s">
                4. If the number is the same, the choice is validated, otherwise you have to start again!
            </h1>
        </header>


        <!-- <header>
            <img id="step1" class="animate__animated animate__fadeInUp animate__faster" src="@/assets/step1.svg">
            <img id="step2" class="animate__animated animate__fadeInUp animate__faster animate__delay-1s"
                src="@/assets/step2.svg">
            <img id="step3" class="animate__animated animate__fadeInUp animate__faster animate__delay-2s"
                src="@/assets/step3.svg">
            <img id="step4" class="animate__animated animate__fadeInUp animate__faster animate__delay-3s"
                src="@/assets/step4.svg">
        </header> -->


        <section>
            <div v-for="(item, index) in imageSources" :key="index" @click="selectImage(item.fileNumber)"
                class="image-container" :data-aos="'fade-up'" :data-aos-delay="index * 140">
                <img :src="item.src" :style="{ opacity: selectedImages[item.fileNumber - 1] ? 0.2 : 1 }">
                <span v-if="selectedImages[item.fileNumber - 1]" class="image-number">{{ item.fileNumber }}</span>
            </div>
        </section>

        <div v-if="selectedImage !== null" id="overlay">
            <div id="modal">
                <div v-if="errorMessage">
                    <h2 id="errorMessage" class="animate__animated animate__pulse">TRY AGAIN :)</h2>
                </div>
                <div v-else-if="successMessage">
                    <h2 id="successMessage" class="animate__animated animate__pulse">GOOD! <br>Take another card</h2>
                </div>
                <div v-else>
                    <h2>Return the card,<br> is it number {{ selectedImage }}?</h2>
                    <div id="buttons-container">
                        <button class="myButton" @click="confirmSelection">OUI</button>
                        <button class="myButton" @click="declineSelection">NON</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>



export default {
    name: 'ProjetPage',
    data() {
        return {
            imageSources: [],
            selectedImages: Array(12).fill(false),
            selectedImage: null,
            errorMessage: false,
            successMessage: false,
            scrollPosition: 0,

        };
    },

    created() {
        this.imageSources = this.shuffleImages(
            Array.from({ length: 12 }, (_, i) => {
                const fileNumber = i + 1;
                return {
                    src: require(`@/assets/videoImg/${String(fileNumber).padStart(4, '0')}.png`),
                    fileNumber
                };
            })
        );
    },

    mounted() {

    },


    methods: {
        shuffleImages(array) {
            let currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        },
        selectImage(fileNumber) {
            if (!this.selectedImages[fileNumber - 1]) {
                this.selectedImage = fileNumber;
                this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                document.body.style.position = 'fixed';
                document.body.style.top = `-${this.scrollPosition}px`;
            }
        },
        confirmSelection() {
            this.successMessage = true;
            setTimeout(() => {
                this.selectedImages[this.selectedImage - 1] = true;
                this.selectedImage = null;
                this.successMessage = false;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, this.scrollPosition);
            }, 3000);
        },
        declineSelection() {
            this.errorMessage = true;
            setTimeout(() => {
                this.errorMessage = false;
                this.selectedImage = null;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, this.scrollPosition);
            }, 3000);
        },
    },
    watch: {
        selectedImages: {
            handler() {
                const allImagesValidated = this.selectedImages.every(validated => validated);
                if (allImagesValidated) {
                    this.$router.push('/victory');
                }
            },
            deep: true
        }
    }
}
</script>


<style scoped>
h1 {
    border: 3px solid white;
    padding: 0.5em;
    border-radius: 20px;
    height: 200px;
}

#btninfos {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #EBEBEB;
    position: absolute;
    right: 30px;
    top: 30px;
    display: flex;
}

#fleche {
    margin: auto;
    color: #321e1a;
    font-family: 'n27regular';
}

/* header {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 25rem));
    justify-content: center;
    grid-gap: 1rem; 
    padding-top: 2rem;
    margin: auto;
} */

header {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 25rem));
    justify-content: center;
    grid-gap: 2rem;
    padding-top: 5rem;
    margin: auto;
}

#logo {
    /* padding-top: 10px; */
    width: 150px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 30rem));
    justify-content: center;
}

img {
    width: 100%;
}

img:hover {
    cursor: pointer;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #321e1a;
    ;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
}

#buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: stretch;
}


#errorMessage {
    font-family: 'n27regular';
    color: #EBEBEB;
    font-size: 44px;
    padding: 0;
    font-weight: 400;
    text-align: center;
}

.image-container {
    position: relative;
}

.image-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'n27regular';
    color: #EBEBEB;
    font-size: 4em;
    padding: 0;
}


.myButton {
    font-family: 'n27regular';
    background-color: #EBEBEB;
    border-radius: 28px;
    display: inline-block;
    cursor: pointer;
    color: #000000;
    font-size: 23px;
    padding: 16px 31px;
    text-decoration: none;
    border: none;
}

.myButton:hover {
    background-color: #EBEBEB;
}

.myButton:active {
    position: relative;
    top: 1px;
}
</style>
