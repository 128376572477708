<!-- src/views/Infos.vue -->

<template>
    <div id="InfosPage">
        <router-link id="btnprojet" to="/Projet" class="animate__animated animate__slideInLeft">
            <p id="fleche">➜</p>
        </router-link>
        <section>
            <h1 class=" animate__animated animate__fadeInUp">This website is a project by Nicolas Tilly in Cards & Coding
                digital card
                research.
                <br><br>
                2023
            </h1>
        </section>
    </div>
</template>

<script>
export default {
    name: 'InfosPage'
}
</script>

<style scoped>
#InfosPage {
    width: 100%;
    height: 100vh;
    display: flex;
    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
}

section {
    width: 94%;
    display: flex;
}

h1 {
    margin: auto;
}

#btnprojet {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #EBEBEB;
    position: absolute;
    left: 20px;
    top: 30px;
    display: flex;
}

#fleche {
    margin: auto;
    color: #321e1a;
    font-family: 'n27regular';
    transform: rotate(-180deg);
}
</style>