<template>
  <router-view></router-view>
  <!-- <transition name="slide" mode="out-in">
    <router-view></router-view>
  </transition> -->
</template>

<script>

export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
.slide-enter-active {
  animation: slide-in 0.7s ease-in-out forwards;
}

.slide-leave-active {
  animation: slide-out 0.7s ease-in-out forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
