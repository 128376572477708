
<template>
    <div id="VictoryPage">

        <h1>VICTORY (AR page)</h1>
    </div>
</template>

<script>

export default {
    name: 'VictoryPage',
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    },

}
</script>


<style scoped>
h1 {
    color: rgba(229, 222, 221, 0.569);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 47%;
    z-index: 1;
    text-align: center;
    padding: 0;
}
</style>