<!-- src/views/Home.vue -->

<template>
    <div id="HomePage">
        <router-link @click.prevent="beginTransition" id="imagesLink" to="/Projet">
            <img class="animate__animated animate__backInUp animate__slow" :class="transitionName" id="videoLink"
                :src="currentImage" alt="Animation">
        </router-link>

        <h1 v-if="!imageClicked" class="animate__animated animate__bounceInDown animate__delay-2s">PRESS ME!</h1>
    </div>
</template>

<script>

export default {
    name: 'HomePage',
    data() {
        return {
            imageIndex: 1,
            totalImages: 12,
            currentImage: '',
            //audio: new Audio('/sons/button2.mp3'),
            imageClicked: false,
        }
    },
    mounted() {
        this.startAnimation();
    },
    methods: {
        // playSound() {
        //     this.audio.play();
        // },
        beginTransition() {
            //this.playSound();
            this.transitionName = 'animate__animated animate__bounceOutUp';
            this.imageClicked = true;
        },
        startAnimation() {
            this.currentImage = this.getImageSrc(this.imageIndex);
            setInterval(() => {
                this.imageIndex++;
                if (this.imageIndex > this.totalImages) {
                    this.imageIndex = 1;
                }
                this.currentImage = this.getImageSrc(this.imageIndex);
            }, 1000); // Remplacer 1000 par le nombre de millisecondes entre chaque image
        },
        getImageSrc(index) {
            return require(`@/assets/videoImg/${String(index).padStart(4, '0')}.png`);
        },
    },
    beforeRouteLeave(to, from, next) {
        this.beginTransition();
        setTimeout(() => {
            next();
        }, 1400);
    }

}
</script>


<style scoped>
#HomePage {
    width: 100%;
    height: 100vh;
    display: flex;
    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
}

#imagesLink {
    margin: auto;
    transform: scale(1.3);
    filter: drop-shadow(0px 10px 53px rgba(234, 167, 253, 0.4));
}


h1 {
    color: rgba(50, 30, 26, 0.831);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 47%;
    z-index: 1;
    text-align: center;
    padding: 0;
}


img {
    width: 100%;
}
</style>