// src/router.js

import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/Home.vue'
import ProjetPage from './views/Projet.vue'
import InfosPage from './views/Infos.vue'
import victoryPage from './views/victory.vue'

const routes = [
    { path: '/', component: HomePage },
    { path: '/Projet', component: ProjetPage },
    { path: '/Infos', component: InfosPage },
    { path: '/victory', component: victoryPage },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
